<template>
    <el-card class="box-card" sytle="padding: 5px;">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <div>
                <el-form-item label="游戏模式">
                    <el-select
                        v-model="form.mode"
                        placeholder="全模式"
                        clearable
                        class="mode_c"
                        size="small"
                    >
                        <el-option
                            v-for="item in AllMode"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="指定阵营">
                    <el-select
                        v-model="form.identity"
                        placeholder="全阵营"
                        clearable
                        class="identity_c"
                        size="small"
                    >
                        <el-option
                            v-for="item in AllIdentity"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="玩家昵称">
                    <el-input
                        v-model="form.user"
                        placeholder="全体玩家"
                        clearable
                        class="user_c"
                        size="small"
                    ></el-input>
                </el-form-item>
                <el-form-item label="对局人数">
                    <el-select
                        v-model="form.min"
                        placeholder=""
                        clearable
                        class="min_c"
                        size="small"
                    >
                        <el-option
                            v-for="item in 8"
                            :key="item + 'min'"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                    至
                    <el-select
                        v-model="form.max"
                        placeholder=""
                        clearable
                        class="max_c"
                        size="small"
                    >
                        <el-option
                            v-for="item in 8"
                            :key="item + 'max'"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div>
                <el-form-item label="统计时间">
                    <el-date-picker
                        v-model="form.starttime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="所有时间"
                        size="small"
                        class="starttime_c">
                    </el-date-picker>
                    至
                    <el-date-picker
                        v-model="form.endtime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="所有时间"
                        size="small"
                        class="endtime_c">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="游戏版本">
                    <el-input
                        v-model="form.versionmin"
                        placeholder=""
                        clearable
                        class="banben_c"
                        size="small"
                    ></el-input>
                    至
                    <el-input
                        v-model="form.versionmax"
                        placeholder=""
                        clearable
                        class="banben_c"
                        size="small"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="search">搜索</el-button>
                    <el-button type="warning" plain @click="resetbtn('searchform')"
                        >重置</el-button
                    >
                </el-form-item>
            </div>
        </el-form>
    </el-card>
</template>

<script>
export default {
    name: 'QueryRoleUseCountSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                mode:'',
                identity: '',
                user:'',
                min: '',
                max: '',
                starttime: '',
                endtime: '',
                versionmin: '',
                versionmax: '',
                page: 1
            }
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.form = {
                mode:'',
                identity: '',
                user:'',
                min: '',
                max: '',
                starttime: '',
                endtime: '',
                versionmin: '',
                versionmax: '',
                page: 1
            }
        },
        addTeam() {
            this.$emit('addTeam')
        },
    },
    filters: {},
    props: {
        AllMode: {
            type: Array,
            default() {
                return []
            }
        },
        AllIdentity: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style lang='less' scoped>
.box-card .el-form-item {
    margin-bottom: 3px;
}
.mode_c,.identity_c {
    width: 105px;
}
.user_c {
    width: 200px;
}
.min_c,.max_c {
    width: 70px;
}
.starttime_c,.endtime_c {
    width: 130px;
}
.banben_c {
    width: 85px;
}
// .el-date-editor.el-input {
//     width: 100%;
// }
</style>