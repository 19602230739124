import request from '../request'

//个人对局列表
export const QueryRoleUseCountRequest = p => {
    return request({
        method:'GET',
        url:'QueryRole/UseCount',
        params: p
    })
}

//角色使用详情
export const QueryRoleUseDetailsRequest = p => {
    return request({
        method:'GET',
        url:'QueryRole/UseDetails',
        params: p
    })
}