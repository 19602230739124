<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="900px"
            :center="true"
            top="10px"
            :close-on-click-modal="false"
            :before-close="() => exitUseDetailsDialog()"
        >
            <el-row :gutter="20">
                <el-col :span="6" style="text-align: center;">
                    <img :src="UseCountDetailsData.role_information.picture[0]['url']" height="130px" alt="" style="border-radius: 10px;">
                    <div v-if="UseCountDetailsData.role_information.gender === 1"><img :src="$store.state.baseURL+'icon/xingbienan.png'" height="10px"><span style="color: #2894FF;">{{ UseCountDetailsData.role_information.name }}</span></div>
                    <div v-else-if="UseCountDetailsData.role_information.gender === 2"><img :src="$store.state.baseURL+'icon/xingbienv.png'" height="10px"><span style="color: #FF60AF;">{{ UseCountDetailsData.role_information.name }}</span></div>
                </el-col>
                <el-col :span="18">
                    <el-descriptions
                        direction="vertical"
                        :column="4"
                        :labelStyle="{'text-align':'center'}"
                        :contentStyle="{'text-align':'center'}"
                        size="small"
                        border
                    >
                        <el-descriptions-item :span="3" :labelStyle="{'width':'300px','text-align':'center'}">
                            <template slot="label">
                                <span>统计时间</span>
                            </template>
                            <el-date-picker
                                v-model="cond.starttime"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="所有时间"
                                size="small"
                                style="width:130px;">
                            </el-date-picker>
                            至
                            <el-date-picker
                                v-model="cond.endtime"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="所有时间"
                                size="small"
                                style="width:130px;">
                            </el-date-picker>
                        </el-descriptions-item>
                        <el-descriptions-item :labelStyle="{'width':'200px','text-align':'center'}">
                            <template slot="label">
                                <span>游戏版本</span>
                            </template>
                            <el-input
                                v-model="cond.versionmin"
                                placeholder=""
                                clearable
                                class="banben_c"
                                size="small"
                                style="width:70px;"
                            ></el-input>
                            至
                            <el-input
                                v-model="cond.versionmax"
                                placeholder=""
                                clearable
                                class="banben_c"
                                size="small"
                                style="width:70px;"
                            ></el-input>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>对局人数</span>
                            </template>
                            <el-select
                                v-model="cond.min"
                                placeholder=""
                                clearable
                                class="min_c"
                                size="small"
                                style="width:60px;"
                            >
                                <el-option
                                    v-for="item in 8"
                                    :key="item + 'min'"
                                    :label="item"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                            至
                            <el-select
                                v-model="cond.max"
                                placeholder=""
                                clearable
                                class="max_c"
                                size="small"
                                style="width:60px;"
                            >
                                <el-option
                                    v-for="item in 8"
                                    :key="item + 'max'"
                                    :label="item"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                        </el-descriptions-item>
                        <el-descriptions-item :labelStyle="{'width':'100px','text-align':'center'}">
                            <template slot="label">
                                <span>游戏模式</span>
                            </template>
                            <el-select
                                v-model="cond.mode"
                                placeholder="全模式"
                                clearable
                                size="small"
                                style="width: 100px;"
                            >
                                <el-option
                                    v-for="item in AllMode"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-descriptions-item>
                        <el-descriptions-item :labelStyle="{'width':'100px','text-align':'center'}">
                            <template slot="label">
                                <span>指定阵营</span>
                            </template>
                            <el-select
                                v-model="cond.identity"
                                placeholder="全阵营"
                                clearable
                                size="small"
                                style="width: 100px;"
                            >
                                <el-option
                                    v-for="item in AllIdentity"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>搜索</span>
                            </template>
                            <el-button type="primary" plain size="small" @click="search()">查询</el-button>
                            <el-button type="warning" plain size="small" @click="resetbtn()">重置</el-button>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-col>
            </el-row>
            <div style="margin-top:10px;text-align: center;font-weight: bold;font-size: 16px;">胜场榜单</div>
            <el-row :gutter="20" style="margin-top:10px;">
                <el-col :span="11">
                    <el-row :gutter="12" v-for="item in 3" :key="item+'paiming'" style="margin-top: 20px;">
                        <el-col :span="10" v-if="UseCountDetailsData.data[item-1]">
                            <div style="text-align: center;">
                                <img v-if="item === 1" :src="$store.state.baseURL+'icon/jinpai.png'" height="50px" style="margin-bottom: 50px;">
                                <img v-else-if="item === 2" :src="$store.state.baseURL+'icon/yinpai.png'" height="50px" style="margin-bottom: 50px;">
                                <img v-else-if="item === 3" :src="$store.state.baseURL+'icon/tongpai.png'" height="50px" style="margin-bottom: 50px;">
                                <img :src="UseCountDetailsData.data[item-1]['user_skin']" height="130px" alt="" style="border-radius: 10px;">
                            </div>
                        </el-col>
                        <el-col :span="14" v-if="UseCountDetailsData.data[item-1]">
                            <div >
                                <el-descriptions
                                    :column="1"
                                    :labelStyle="{'width':'50px','text-align':'center','background-color':'#FFFFFF'}"
                                    :contentStyle="{'text-align':'center','background-color':'#FFFFFF'}"
                                    size="small"
                                    border
                                >
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>昵称</span>
                                        </template>
                                        <span>{{ UseCountDetailsData.data[item-1]['user_name'] }}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>胜场</span>
                                        </template>
                                        <span style="color: #CE0000;">{{ UseCountDetailsData.data[item-1]['win_num'] }}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>胜率</span>
                                        </template>
                                        <span style="color: #2828FF;">{{ UseCountDetailsData.data[item-1]['win_ratio'] }}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>总场</span>
                                        </template>
                                        <span>{{ UseCountDetailsData.data[item-1]['num'] }}</span>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="13">
                    <el-table
                        :data="UseCountDetailsData.data"
                        style="width: 100%"
                        border
                        stripe
                        highlight-current-row
                        size="small"
                        height="480px"
                        show-summary
                        :summary-method="getSummaries"
                        ref="UseCountDetailsDataTable"
                    >
                        <el-table-column
                            align="center"
                            prop="index"
                            label="排名"
                            min-width="60px"
                        >
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="user_name"
                            label="玩家昵称"
                            min-width="170px"
                        >
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="win_num"
                            label="胜场"
                            min-width="60px"
                        >
                            <template slot-scope="scope">
                                <span style="color: #CE0000;">{{ scope.row.win_num }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="win_ratio"
                            label="胜率"
                            min-width="60px"
                        >
                            <template slot-scope="scope">
                                <span style="color: #2828FF;">{{ scope.row.win_ratio }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="num"
                            label="总场次"
                            min-width="60px"
                        >
                            <template slot-scope="scope">
                                <span>{{ scope.row.num }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
import { QueryRoleUseDetailsRequest } from '@/network/query/QueryRole.js'

export default {
    name:'',
    data(){
        return {
            cond: {
                role_id: '',
                starttime: '',
                endtime: '',
                versionmin: '',
                versionmax: '',
                mode:'',
                identity: '',
                min: '',
                max: ''
            },
            UseCountDetailsData: {
                role_information: {
                    gender: 1,
                    name: '神秘人',
                    picture:[
                        {url: ''}
                    ]
                },
                me: {
                    index: 0,
                    user_name: '',
                    num: 0,
                    win_ratio: 0
                },
                data: [
                    {user_name: '',user_skin:'',num: '',win_ratio: ''},
                    {user_name: '',user_skin:'',num: '',win_ratio: ''},
                    {user_name: '',user_skin:'',num: '',win_ratio: ''}
                ]
            }
        }
    },
    computed:{},
    methods:{
        search() {
            this.GetQueryRoleUseDetails()
        },
        resetbtn() {
            this.cond.starttime = ''
            this.cond.endtime = ''
            this.cond.versionmin = ''
            this.cond.versionmax = ''
            this.cond.mode = ''
            this.cond.identity = ''
            this.cond.min = ''
            this.cond.max = ''
            this.GetQueryRoleUseDetails()
        },
        GetQueryRoleUseDetails() {
            if(this.cond.role_id) {
                QueryRoleUseDetailsRequest(this.cond)
                .then((s) => {
                    if (s.status === 0) {
                        this.UseCountDetailsData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally()
            }
        },
        exitUseDetailsDialog() {
            this.cond.role_id = 0
            this.resetbtn()

            this.UseCountDetailsData = {
                role_information: {
                    gender: 1,
                    name: '神秘人',
                    picture:[
                        {url: ''}
                    ]
                },
                me: {
                    index: 0,
                    user_name: '',
                    num: 0,
                    win_num: 0,
                    win_ratio: '0%'
                },
                data: [
                    {user_name: '',user_skin:'',num: '',win_num: '',win_ratio: ''},
                    {user_name: '',user_skin:'',num: '',win_num: '',win_ratio: ''},
                    {user_name: '',user_skin:'',num: '',win_num: '',win_ratio: ''}
                ]
            }

            this.$emit('exitUseDetailsDialog')
        },
        getSummaries(param) {
            const me = this.UseCountDetailsData.me
            const { columns, data } = param;
            const sums = [];
            columns.forEach((value,index) => {
                if(index === 0) {
                    if(me.index === 0) {
                        sums[index] = '没眼看'
                    } else if(me.index === -1) {
                        sums[index] = '未使用'
                    } else {
                        sums[index] = me.index
                    }
                } else if(index === 1) {
                    sums[index] = me.user_name
                } else if((index === 2)) {
                    sums[index] = me.win_num
                } else if((index === 3)) {
                    sums[index] = me.win_ratio
                } else if((index === 4)) {
                    sums[index] = me.num
                }
            })

            return sums;
        }
    },
    filters:{},
    props:{
        dialogFormVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        RoleId: {
            type: Number,
            default() {
                return 0
            }
        },
        AllMode: {
            type: Array,
            default() {
                return []
            }
        },
        AllIdentity: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){
        // this.$nextTick(() => {
        //     this.$refs['UseCountDetailsDataTable'].doLayout();
        // })
    },
    components:{},
    watch:{
        RoleId(newVal) {
            this.cond.role_id = newVal
            this.GetQueryRoleUseDetails()
        }
    },
}
</script>

<style lang='less'>
.el-table__footer {
    font-weight: bold;
}
</style>