<template>
    <div v-loading.fullscreen.lock="loading">
        <query-role-use-count-search-bar-component
            :AllMode="AllMode"
            :AllIdentity="AllIdentity"
            @search="get_query_role_use_count_index"
        ></query-role-use-count-search-bar-component>
        <el-card class="box_card">
            <el-table
                :data="TableData"
                style="width: 100%"
                border
                stripe
                highlight-current-row
                size="mini"
            >
                <el-table-column>
                    <template v-slot:header>
                        <div style="text-align: center;">
                            <span style="font-size: 20px;font-family: HarmonyOS_Sans_Black;color: #4F4F4F;">角色使用统计</span>
                            <div style="display: inline;float: left;">
                                <el-radio-group v-model="cond.sort" size="small" :fill="sort_fill" @input="sort_change">
                                    <el-radio-button :label=1>按胜率排序</el-radio-button>
                                    <el-radio-button :label=2>按场次排序</el-radio-button>
                                </el-radio-group>
                            </div>
                            <div style="display: inline;float: right;">
                                <el-radio-group v-model="cond.gender" size="small" :fill="gender_fill" @input="gender_change">
                                    <el-radio-button :label=0>所有性别</el-radio-button>
                                    <el-radio-button :label=1>只看男性</el-radio-button>
                                    <el-radio-button :label=2>只看女性</el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>
                    </template>
                    <el-table-column
                        align="center"
                        prop="mc"
                        label="排名"
                        min-width="50px"
                    >
                        <template slot-scope="scope">
                            <span>
                                <img v-if="mingci(scope) === 1" :src="$store.state.baseURL+'icon/jinpai.png'" height="20px">
                                <img v-else-if="mingci(scope) === 2" :src="$store.state.baseURL+'icon/yinpai.png'" height="20px">
                                <img v-else-if="mingci(scope) === 3" :src="$store.state.baseURL+'icon/tongpai.png'" height="20px">
                                <span v-else>{{ mingci(scope) }}</span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="role_name"
                        label="角色名称"
                        min-width="110px"
                    >
                        <template slot-scope="scope">
                            <div v-if="scope.row.role_gender === 1">
                                <img :src="$store.state.baseURL+'icon/xingbienan.png'" height="10px"/>
                                <span style="color: #2894FF;">{{ scope.row.role_name }}</span>
                            </div>
                            <div v-else-if="scope.row.role_gender === 2">
                                <img :src="$store.state.baseURL+'icon/xingbienv.png'" height="10px"/>
                                <span style="color: #FF60AF;">{{ scope.row.role_name }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="role_hide"
                        label="属性"
                        min-width="100px"
                    >
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.role_hide === 0" type="info" effect="plain" size="small">隐藏角色</el-tag>
                            <el-tag v-else-if="scope.row.role_hide === 1" type="success" effect="plain" size="small">公开角色</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="num"
                        label="场次"
                        min-width="80px"
                    >
                        <template slot-scope="scope">
                            <span>{{ scope.row.num }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="win_ratio"
                        label="胜率"
                        min-width="80px"
                    >
                        <template slot-scope="scope">
                            <span>{{ scope.row.win_ratio }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="角色资料"
                        min-width="80px"
                    >
                        <template slot-scope="scope">
                            <img :src="$store.state.baseURL+'icon/jueseziliao.png'" height="20px" @click="RoleDetails(scope.row.role_id)" />
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="详细数据"
                        min-width="80px"
                    >
                        <template slot-scope="scope">
                            <img :src="$store.state.baseURL+'icon/xiangxishuju.png'" height="20px" @click="CountDetails(scope.row.role_id)" />
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table>
        </el-card>
        <el-card class="box-card">
            <el-descriptions
                :column="3"
                :labelStyle="{'text-align':'center'}"
                :contentStyle="{'text-align': 'center'}"
                border
                size="small"
            >
                <el-descriptions-item>
                    <template slot="label">
                        <div style="font-weight: bold;">
                            <span style="color: #C6A300;" v-if="cond.gender === 0">全角色</span>
                            <span style="color: #2894FF;" v-else-if="cond.gender === 1">男性角色</span>
                            <span style="color: #FF60AF;" v-else-if="cond.gender === 2">女性角色</span>
                            <span>总场次</span>
                        </div>
                    </template>
                    <span>{{ all_num }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <div style="font-weight: bold;">
                            <span style="color: #C6A300;" v-if="cond.gender === 0">全角色</span>
                            <span style="color: #2894FF;" v-else-if="cond.gender === 1">男性角色</span>
                            <span style="color: #FF60AF;" v-else-if="cond.gender === 2">女性角色</span>
                            <span>平均场次</span>
                        </div>
                    </template>
                    <span>{{ all_average_num }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <div style="font-weight: bold;">
                            <span style="color: #C6A300;" v-if="cond.gender === 0">全角色</span>
                            <span style="color: #2894FF;" v-else-if="cond.gender === 1">男性角色</span>
                            <span style="color: #FF60AF;" v-else-if="cond.gender === 2">女性角色</span>
                            <span>平均胜率</span>
                        </div>
                    </template>
                    <span>{{ all_average_win_ratio }}</span>
                </el-descriptions-item>
            </el-descriptions>
            <div class="block" style="margin-top: 10px;">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="cond.page"
                    :page-sizes="this.$store.state.pageSizes"
                    :page-size="cond.limit"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
        </el-card>
        <card-role-edit-component
            :id="CardRoleId"
            :show_type=0
            :dialogFormVisible="CardRoleFormVisible"
            :card_role_details_data="CardRoleDetailsData"
            :AllGender="AllGender"
            :AllGroup="AllGroup"
            :AllHide="AllHide"
            @exitCardRoleDialog="CardRoleFormVisible = false"
        ></card-role-edit-component>
        <query-role-use-details-edit-component
            :dialogFormVisible="UseDetailsDialogFormVisible"
            :RoleId="RoleId"
            :AllMode="AllMode"
            :AllIdentity="AllIdentity"
            @exitUseDetailsDialog="exitUseDetailsDialog"
        ></query-role-use-details-edit-component>
    </div>
</template>

<script>
import { QueryRoleUseCountRequest } from '@/network/query/QueryRole.js'
import { CardRoleDetailsRequest } from '@/network/card/CardRole.js'

import QueryRoleUseCountSearchBarComponent from '@/components/query/QueryRole/QueryRoleUseCountSearchBarComponent'
import CardRoleEditComponent from '@/components/card/CardRole/CardRoleEditComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import QueryRoleUseDetailsEditComponent from '@/components/query/QueryRole/QueryRoleUseDetailsEditComponent'

export default {
    name:'',
    data(){
        return {
            loading: true,
            cond: {
                mode: '',
                identity: '',
                user: '',
                min: '',
                max: '',
                starttime: '',
                endtime: '',
                versionmin: '',
                versionmax: '',
                sort: 1,
                gender: 0,
                page: 1,
                limit: 10
            },
            total:0,
            all_num: 0, //全角色总场次
            all_average_num: 0, //全角色平均场次
            all_average_win_ratio: '0%',    //全角色平均胜率
            TableData: [],
            AllMode:[
                { id: 1,name: '身份' },
                { id: 2,name: '3V3' },
                { id: 3,name: '酱油' },
            ],
            AllIdentity:[
                { id: 1,name: '军潜' },
                { id: 2,name: '酱油' }
            ],
            CardRoleFormVisible:  false,
            CardRoleDetailsData: {},
            CardRoleId: 0,
            AllGender:[
                {id:1,name:'男'},
                {id:2,name:'女'}
            ],
            AllGroup:[
                {id:0,name:'标准系列'},
                {id:1,name:'进阶系列'},
                {id:2,name:'血色密令'},
                {id:3,name:'绝密猎杀'},
                {id:4,name:'致命蔷薇'},
                {id:5,name:'谍影危机'},
                {id:6,name:'特殊系列'}
            ],
            AllHide:[
                {id:0,name:'隐藏角色'},
                {id:1,name:'公开角色'}
            ],
            UseDetailsDialogFormVisible : false,
            RoleId:  0
        }
    },
    computed:{
        sort_fill() {
            if(this.cond.sort === 1) {
                return '#9393FF'
            } else if(this.cond.sort === 2) {
                return '#00CACA'
            }
        },
        gender_fill() {
            if(this.cond.gender === 0) {
                return '#C6A300'
            } else if(this.cond.gender === 1) {
                return '#2894FF'
            } else if(this.cond.gender === 2) {
                return '#FF60AF'
            }
        }
    },
    methods:{
        mingci(scope) {
            return (this.cond.page -1) * this.cond.limit  + scope['$index'] + 1
        },
        get_query_role_use_count_index(param = {}) {
            this.cond.mode = param.mode ?? this.cond.mode
            this.cond.identity = param.identity ?? this.cond.identity
            this.cond.user = param.user ?? this.cond.user
            this.cond.min = param.min ?? this.cond.min
            this.cond.max = param.max ?? this.cond.max
            this.cond.starttime = param.starttime ?? this.cond.starttime
            this.cond.endtime = param.endtime ?? this.cond.endtime
            this.cond.versionmin = param.versionmin ?? this.cond.versionmin
            this.cond.versionmax = param.versionmax ?? this.cond.versionmax
            this.cond.sort = param.sort ?? this.cond.sort
            this.cond.gender = param.gender ?? this.cond.gender
            this.cond.page = param.page ?? this.cond.page

            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            this.loading = true
            QueryRoleUseCountRequest(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.TableData = s.result.data
                        this.total = s.result.total
                        this.all_num = s.result.all_num
                        this.all_average_num = s.result.all_average_num
                        this.all_average_win_ratio = s.result.all_average_win_ratio
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })

            this.$nextTick(() => {
                document.getElementById('el_main').scrollTop = 0;
            })
        },
        sort_change(val) {
            this.cond.sort = val
            this.cond.page = 1
            this.get_query_role_use_count_index()
        },
        gender_change(val) {
            this.cond.gender = val
            this.cond.page = 1
            this.get_query_role_use_count_index()
        },
        RoleDetails(role_id) {
            this.CardRoleId = role_id
            CardRoleDetailsRequest(role_id)
                .then((s) => {
                    if (s.status === 0) {
                        this.CardRoleDetailsData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                this.CardRoleFormVisible = true
        },
        CountDetails(id) {
            this.RoleId = id
            this.UseDetailsDialogFormVisible = true
        },
        exitUseDetailsDialog() {
            this.RoleId = 0
            this.UseDetailsDialogFormVisible = false
        },
        handleSizeChange(val) {
            this.get_query_role_use_count_index({ limit: val })
        },
        handleCurrentChange(val) {
            this.get_query_role_use_count_index({ page: val })
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_query_role_use_count_index()
    },
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{
        QueryRoleUseCountSearchBarComponent,
        CardRoleEditComponent,
        CommonPageComponent,
        QueryRoleUseDetailsEditComponent
    },
    watch:{},
}
</script>

<style lang='less'></style>